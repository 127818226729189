<template>
  <v-card flat>
    <v-card-title class="grey lighten-4 font-weight-bold body-2 py-0" style="color: rgba(0,0,0,.54);height: 56px;">
      {{ $t("dashboard.access.monthly_events") }}
      <v-spacer />
      <id-button @click="onRefreshData">
        <template #icon>
          <v-icon left>mdi-refresh</v-icon>
        </template>
        {{ $t("buttons.refresh") }}
      </id-button>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="table.headers"
        :items="table.data"
        :options.sync="table.options"
        :server-items-length="table.totalItems"
        single-select
        :disable-filtering="table.loading"
        :disable-pagination="table.loading"
        :loading="table.loading"
        :footer-props="{ itemsPerPageOptions: [10] }"
        class="elevation-0 event-table"
      >
        <template #item="row">
          <tr :key="row.item.id">
            <td>{{ row.index + 1 }}</td>
            <td>
              <app-identity-icon :type="row.item.terminalType" />
            </td>
            <td>
              {{ row.item.terminalName }}
            </td>
            <td>
              <app-identity-icon category="event" :type="row.item.eventType" />
            </td>
            <td>
              <app-identity-icon :type="row.item.identityType" />
            </td>
            <td>
              {{ row.item.timestamp | formattedDateTimeMinute }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description last-events Component Logic
   * @date 29.07.2020
   */
  import { FILTER_CLOCK_RECORDS } from "../query";

  export default {
    name: "last-events",
    props: {
      beginDate: String,
      endDate: String
    },
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          employees: [vm.$store.state.auth.user.id],
          beginDate: vm
            .$moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: vm
            .$moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        },
        options: {},
        pagination: {
          rowsPerPage: 20,
          sortBy: "timestamp",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: "no",
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: vm.$t("dashboard.access.table.terminal_type"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: vm.$t("dashboard.access.table.terminal"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: vm.$t("dashboard.access.table.event_type"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: vm.$t("dashboard.access.table.identity_type"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          },
          {
            text: vm.$t("dashboard.access.table.time"),
            align: "left",
            sortable: false,
            value: "",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      },
      beginDate(v) {
        this.table.criteria.beginDate = v;
      },
      endDate(v) {
        this.table.criteria.endDate = v;
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: FILTER_CLOCK_RECORDS,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateClockRecords.content || [];
              this.table.totalItems = data.filterWithPaginateClockRecords.pageInfo.totalElements;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    },
    created() {
      this.$eventBus.$on("add:clock-records", () => this.fetchItems());
    }
  };
</script>

<style scoped lang="scss">
  .event-table::v-deep {
    .v-data-table__wrapper {
      min-height: 698px;
    }
  }
</style>
